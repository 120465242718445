"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatTimeRemaining = exports.drawImageScaled = exports.cmyk2rgb = exports.rgb2cmyk = exports.sum = exports.randomInt = exports.arrayRange = exports.shuffle = exports.dataURLtoBlob = exports.rangeInitial = void 0;
const rangeInitial = function (value, def, min, max, step = 1) {
    let v;
    if (typeof value === 'number') {
        v = Math.min(max, Math.max(min, value));
    }
    else {
        v = Math.min(max, Math.max(min, def));
    }
    if ((v % step) != 0) {
        if ((max - v) < (v - min)) {
            while ((v % step) != 0) {
                v += 1;
            }
        }
        else {
            while ((v % step) != 0) {
                value -= 1;
            }
        }
    }
    return v;
};
exports.rangeInitial = rangeInitial;
const dataURLtoBlob = (dataurl) => {
    // @ts-ignore
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1], bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
};
exports.dataURLtoBlob = dataURLtoBlob;
const shuffle = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
};
exports.shuffle = shuffle;
const arrayRange = (start, stop, step = 1) => {
    return Array.from({ length: (stop - start) / step + 1 }, (value, index) => start + index * step);
};
exports.arrayRange = arrayRange;
const randomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
};
exports.randomInt = randomInt;
const sum = (values) => values.length > 0 ? values.reduce((a, b) => a + b, 0) : 0;
exports.sum = sum;
const rgb2cmyk = function ([rgb, a]) {
    const r = rgb[0] / 255;
    const g = rgb[1] / 255;
    const b = rgb[2] / 255;
    const k = +(1 - Math.max(r, g, b)).toFixed(2);
    const c = +((1 - r - k) / (1 - k) || 0).toFixed(2);
    const m = +((1 - g - k) / (1 - k) || 0).toFixed(2);
    const y = +((1 - b - k) / (1 - k) || 0).toFixed(2);
    return [[c * 100, m * 100, y * 100, k * 100], a];
};
exports.rgb2cmyk = rgb2cmyk;
const cmyk2rgb = function (c, m, y, k, normalized) {
    c = (c / 100);
    m = (m / 100);
    y = (y / 100);
    k = (k / 100);
    c = c * (1 - k) + k;
    m = m * (1 - k) + k;
    y = y * (1 - k) + k;
    var r = 1 - c;
    var g = 1 - m;
    var b = 1 - y;
    if (!normalized) {
        r = Math.round(255 * r);
        g = Math.round(255 * g);
        b = Math.round(255 * b);
    }
    return [r, g, b];
};
exports.cmyk2rgb = cmyk2rgb;
function drawImageScaled(img, ctx) {
    var canvas = ctx.canvas;
    var hRatio = canvas.width / img.width;
    var vRatio = canvas.height / img.height;
    var ratio = Math.min(hRatio, vRatio);
    var centerShift_x = (canvas.width - img.width * ratio) / 2;
    var centerShift_y = (canvas.height - img.height * ratio) / 2;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(img, 0, 0, img.width, img.height, centerShift_x, centerShift_y, img.width * ratio, img.height * ratio);
}
exports.drawImageScaled = drawImageScaled;
function formatTimeRemaining(sec) {
    if (sec >= 3600) {
        return new Date(sec * 1000).toISOString().substr(11, 8);
    }
    return new Date(sec * 1000).toISOString().substr(14, 5);
}
exports.formatTimeRemaining = formatTimeRemaining;
