"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const utils_1 = require("@src/shared/utils");
class ChimptestCtrl {
    constructor($scope, $timeout, ModalServiceFactory, ConfigService, SoundService) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.ModalServiceFactory = ModalServiceFactory;
        this.ConfigService = ConfigService;
        this.SoundService = SoundService;
        this.initial = JSON.parse(localStorage.getItem(`${this.constructor.name}_save`) || 'null') || {
            beginNumbersCount: 4,
            cols: 8,
            rows: 5,
            level: 0,
        };
        this.level = 2;
        this.status = 'game';
        this.game$ = new rxjs_1.Subject();
        this.clickNumber$ = new rxjs_1.Subject();
    }
    $onInit() {
        this.game$.pipe((0, operators_1.startWith)(this.initial), (0, operators_1.map)((initial) => {
            console.log('initial', initial);
            this.status = 'game';
            document.documentElement.style.setProperty('--grid-size', initial.cols.toString());
            if (initial.grid != undefined) {
                return initial;
            }
            else {
                const numbers = (0, utils_1.arrayRange)(1, initial.beginNumbersCount + initial.level, 1);
                const z = Array.from({ length: initial.rows * initial.cols }, (_, index) => {
                    return {
                        x: index - initial.rows * Math.floor(index / initial.rows),
                        y: Math.floor(index / initial.rows),
                    };
                });
                (0, utils_1.shuffle)(z);
                let points = {};
                for (let item of z.splice(0, initial.beginNumbersCount + initial.level).map((item) => {
                    return item;
                })) {
                    points[`${item.x}-${item.y}`] = Object.assign(Object.assign({}, item), { value: numbers.pop(), visible: true, valid: undefined });
                }
                return Object.assign(Object.assign({}, initial), { grid: Array.from({ length: initial.rows }, (_, x) => {
                        return Array.from({ length: initial.cols }, (_, y) => {
                            if (points.hasOwnProperty(`${x}-${y}`)) {
                                return Object.assign({}, points[`${x}-${y}`]);
                            }
                            else {
                                return {
                                    x: x,
                                    y: y,
                                    value: undefined,
                                    visible: true,
                                    valid: undefined,
                                };
                            }
                        });
                    }), numbersCount: initial.beginNumbersCount + initial.level });
            }
        }), (0, operators_1.switchMap)((gameData) => {
            console.log('gameData', gameData);
            this.grid = gameData.grid;
            return this.clickNumber$.pipe((0, operators_1.filter)(() => {
                return this.status == 'game';
            }), (0, operators_1.map)((value, index) => {
                value.valid = value.value == index + 1;
                if ((index == 0) && value.valid) {
                    this.grid.map((row) => row.map((col) => {
                        if ((value.x == col.x) && (value.y == col.y)) {
                            col.valid = true;
                        }
                        col.visible = false;
                    }));
                }
                localStorage.setItem(`${this.constructor.name}_save`, ng.toJson(Object.assign(Object.assign({}, gameData), { grid: ng.copy(gameData.grid).map((row) => row.map((col) => {
                        col.valid = undefined;
                        col.visible = true;
                        return col;
                    })) })));
                if (value.valid == false) {
                    throw new Error(`Valid token not returned ${gameData.number} != ${value.value}`);
                }
                return index;
            }), (0, operators_1.catchError)((err) => {
                console.log(err);
                this.status = 'retry';
                return (0, rxjs_1.of)(null);
            }), (0, operators_1.bufferCount)(gameData.numbersCount), (0, operators_1.take)(gameData.numbersCount));
        }), (0, operators_1.tap)(() => {
            console.log('end Level');
            const maxLevel = this.initial.cols * this.initial.rows - this.initial.beginNumbersCount;
            if (this.initial.level == maxLevel) {
                this.status = 'end';
                localStorage.removeItem(`${this.constructor.name}_save`);
            }
            else {
                this.status = this.status == 'game' ? 'next' : 'retry';
                localStorage.setItem(`${this.constructor.name}_save`, ng.toJson(Object.assign(Object.assign({}, this.initial), { level: this.status == 'next' ? (this.initial.level + 1) : this.initial.level, grid: undefined // Если надо чтобы на новом шаге при обновлении страницы были одинаковые цифры, тут нужно сгенерить сетку
                 })));
            }
        })).subscribe();
    }
    setLevel(level) {
        this.level = level;
    }
    clickNumber(col) {
        if ((col.value != undefined) && (col.valid == undefined))
            this.clickNumber$.next(col);
    }
    newGame(force = false) {
        if (force) {
            localStorage.removeItem(`${this.constructor.name}_save`);
            this.initial.level = 0;
            this.game$.next(Object.assign(Object.assign({}, this.initial), { grid: undefined }));
            return;
        }
        else {
            let blankGrid = false;
            if (this.initial.level == 0) {
                if ((this.grid != undefined) && (this.status != 'retry')) {
                    this.grid.forEach((row) => row.forEach((col) => {
                        if ((col.value == 1) && col.visible) {
                            blankGrid = true;
                        }
                    }));
                }
                else {
                    blankGrid = true;
                }
            }
            if (blankGrid) {
                this.newGame(true);
            }
            else {
                this._confirmNewGame((result) => {
                    if (result) {
                        this.newGame(true);
                    }
                });
            }
        }
    }
    restart() {
        this.game$.next(Object.assign(Object.assign({}, this.initial), { grid: undefined }));
    }
    next() {
        this.initial.level += 1;
        this.game$.next(Object.assign(Object.assign({}, this.initial), { grid: undefined }));
    }
    debugWin() {
        let cols = [];
        this.grid.map((row) => row.map((col) => {
            if ((col.value != undefined) && (col.valid == undefined)) {
                cols.push(col);
            }
        }));
        (0, rxjs_1.from)(cols.sort((a, b) => a.value - b.value)).pipe((0, operators_1.concatMap)((col) => {
            return (0, rxjs_1.timer)(200).pipe((0, operators_1.tap)(() => {
                this.$timeout(() => {
                    this.clickNumber(col);
                });
            }));
        })).subscribe();
    }
    _confirmNewGame(callback) {
        return this.ModalServiceFactory.open({
            id: 'chimptest_new_game',
            component: "confirm-comp",
            scope: this.$scope,
            extraContext: {
                settings: {}
            }
        }).then((result) => {
            if (result) {
                callback ? callback(result) : null;
            }
            else {
                throw { error: 'cancel' };
            }
        });
    }
}
ChimptestCtrl.$inject = ['$scope', '$timeout', 'ModalServiceFactory', 'ConfigService', 'SoundService'];
const appModule = ng.module('app');
appModule.component('gameChimptest', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: ChimptestCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('chimptest/');
    }]);
